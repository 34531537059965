import Input from './input';
import Button from './button';
import ActionButton from "./actionButton";

const Controls = {
    Input,
    Button,
    ActionButton
}

export default Controls;