import ImageOne from '../images/image-2.jpg';
import imageTwo from '../images/image-4.jpg';
import imageThree from '../images/image-5.jpg';
import ImageFour from '../images/image-10.jpg';
import ImageFive from '../images/image-1.jpg';


export const SliderData = [
    {
        title: 'HEALTH PLANTS UGANDA',
        label: 'Natural wellbeing for the world-Prosperity for Uganda',
        alt: '"Their fruit will be for food, and their leaves for healing ...Ezekiel 47:12"',
        image: ImageOne
    },
    {
        title: 'HEALTH PLANTS UGANDA',
        label: 'Natural wellbeing for the world-Prosperity for Uganda',
        alt: '"Their fruit will be for food, and their leaves for healing ...Ezekiel 47:12"',
        image: imageTwo
    },
    {
        title: 'HEALTH PLANTS UGANDA',
        label: 'Natural wellbeing for the world-Prosperity for Uganda',
        alt: '"Their fruit will be for food, and their leaves for healing ...Ezekiel 47:12"',
        image: imageThree
    }, 
    {
        title: 'HEALTH PLANTS UGANDA',
        label: 'Natural wellbeing for the world-Prosperity for Uganda',
        alt: '"Their fruit will be for food, and their leaves for healing ...Ezekiel 47:12"',
        image: ImageFour
    }, 
    {
        title: 'HEALTH PLANTS UGANDA',
        label: 'Natural wellbeing for the world-Prosperity for Uganda',
        alt: '"Their fruit will be for food, and their leaves for healing ...Ezekiel 47:12"',
        image: ImageFive
    }, 
]