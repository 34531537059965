import React from 'react'
import { Dialog,DialogTitle, DialogContent, makeStyles, Typography } from "@material-ui/core";
import Controls from "../components/controls/control";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
    dialogWapper: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))

export default function Popup(props) {

const{ title, children, openPopup, setOpenPopup } = props;
const classes = useStyles();

    return (
        <Dialog open={openPopup} classes={{ paper: classes.dialogWapper}}>
            <DialogTitle className={classes.dialogTitle }>

                <div style={{display: 'flex'}}>

                    <Typography variant="h6" component="div" style={{flexGrow: 1}}>
                        {title}
                    </Typography>

                    <Controls.ActionButton
                        color="secondary"
                        onClick = {() => { setOpenPopup(false) }}>
                            <CloseIcon/>
                    </Controls.ActionButton>

                </div>

            </DialogTitle>

            <DialogContent dividers>
                {children}
            </DialogContent>
            
        </Dialog>
    )
}
